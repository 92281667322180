import React, { useState, useEffect } from 'react';
import './AppDownload.css';

const AppDownload = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    // Event listener to capture 'beforeinstallprompt' event
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // console.log('User accepted the install prompt');
        } else {
          // console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null); // Reset deferredPrompt after handling
      });
    } else {
      // console.log('Installation prompt not available');
      // // You can add additional logic here, such as redirecting to app stores or showing an alert
      // alert("Installation prompt is not available. Please try again later or download from the app store.");
    }
  };

  return (
    <div className="app-download-container">
      <div className="app-download-content">
        <div className="app-mockup">
          <div className="app-screen">
            <div className="app-icon">
                       </div>
            {/* <p>Download Our New App</p> */}
          </div>
        </div>
        <div className="app-info">
          <h1>Download Our App</h1>
          <h2>Luxury At-Home Salon Services- Salon Ease</h2>
          <p>
          Enjoy the best salon and beauty services at home. Salon Ease brings expert grooming and beauty services to your doorstep with easy online booking.
          </p>
          {/* <div className="download-options">
            <img
              src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png?height=60&width=200"
              alt="Download on the App Store"
              className="store-badge"
            />
            <img
              src="https://freeiconshop.com/wp-content/uploads/edd/app-store-badge.png?height=60&width=200"
              alt="Get it on Google Play"
              className="store-badge"
            />
          </div>
          <div className="qr-code">
            <img src="/placeholder.svg?height=100&width=100" alt="QR Code" />
            <p>Scan to download</p>
          </div> */}
          <button className="download-button" onClick={handleInstallClick}>
            Download App
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppDownload;
