import React, { useState, useEffect, useRef, useMemo, lazy } from 'react';
// import Slider from './silder'
import './newhome.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Next from '../../assets/images/arrow-down.png';
import Location from '../../assets/images/location.png';
import CardSlider from './cardslider';
import Testimonial from './testimonial';
import Choose1 from '../../assets/images/choose1.png';
import Choose2 from '../../assets/images/choose2.png';
import Choose3 from '../../assets/images/choose3.png';
import Choose4 from '../../assets/images/choose4.png';
import Choose5 from '../../assets/images/choose5.png';
import Choose6 from '../../assets/images/choose6.png';
import Brand1 from '../../assets/images/brand1.png';
import Brand2 from '../../assets/images/brand2.png';
import Brand3 from '../../assets/images/brand3.png';
import Brand4 from '../../assets/images/brand4.png';
import Brand5 from '../../assets/images/brand5.png';
import Brand6 from '../../assets/images/brand6.png';
import NewQuestion from './accordion/newquestion';
import Bannerimage from './bannerimage';
import SearchPage from './searchpage';
import SearchLocation from './searchlocation';
import axios from 'axios';
import { serverAPI } from '../..';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Mobilebanner from './mobilebanner';
import SeoContent from './seoContent';
import WhatsAppButton from './WhatsAppButton'; // Import WhatsApp button
import AppDownload from '../../../src/installPrompt';
import MobileFooter from '../../layouts/mobile_footer';
const Citiesweserve = lazy(() => import('../../layouts/cities_we_serve'));
export default function NewHome() {
  const navigate = useNavigate();

  const [review, setResview] = useState([]);
  const [categories, setCategories] = useState([]);
  const location = useLocation();
  // const getRootUser = async () => {
  //   try {
  //     const res = await axios.get(`${serverAPI}/getReviewsDetails`, {
  //       withCredentials: true,
  //     });
  //     if (res.status === 200) {
  //       setResview(res.data.result.reviews);
  //     }
  //   } catch (err) {}
  // };

  const isFirstCall = useRef(true);

  useEffect(() => {
    if (isFirstCall.current) {
      isFirstCall.current = false; // Set the flag to false after the first call

      axios
        .get(`${serverAPI}/categories`)
        .then(response => {
          setCategories(response.data);
        })
        .catch(error => {
          setCategories([]);
          console.error('Error fetching categories:', error);
        });
    }
  }, []);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // loacation pop up

  const [isPopupLocOpen, setIsPopupLocOpen] = useState(false);

  const openLocPopup = () => {
    setIsPopupLocOpen(true);
  };

  const closeLocPopup = () => {
    setIsPopupLocOpen(false);
  };

  function toKebabCase(str) {
    return str
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace spaces and non-alphanumeric characters with hyphens
      .replace(/(^-|-$)/g, ''); // Remove leading or trailing hyphens
  }

  const handleServiceClick = item => {
    const pathname = location.pathname;
    const cityMatch = pathname.split('/')[1]; // Get first segment after '/'
    let cityName =
      cityMatch && cityMatch.length > 0 ? cityMatch.split('-')[0] : '';
    const city = cityName.charAt(0).toLowerCase() + cityName.slice(1); // Capitalize first letter
    // console.log(city === '', 'citycity');
    let url = '';
    if (city === '') {
      url = `/at-home-service/${toKebabCase(item.name)}`;
    } else {
      url = `/${city}/${toKebabCase(item.name)}`;
    }
    navigate(url, { state: item });
  };
  const [addkey, setAddKey] = useState(null);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('addkey'));
    if (items) {
      setAddKey(items);
    }
  }, []);
  // New code for animated search input placeholder
  const [placeholderText, setPlaceholderText] = useState("search for ''");

  const placeholderTexts = useMemo(
    () => [
      'facial services',
      'body polish services',
      'body massage services',
      'Rica waxing',
      'Manicure',
      'Pedicure',
      'Makeup services',
    ],
    []
  );

  const typingSpeed = 50; // Speed of typing in milliseconds
  const erasingSpeed = 50; // Speed of erasing in milliseconds
  const holdTime = 2000; // Time to hold the text after typing is complete

  const currentIndex = useRef(0);
  const charIndex = useRef(0);
  const isTyping = useRef(true);

  useEffect(() => {
    const typeEffect = () => {
      if (
        !placeholderTexts ||
        currentIndex.current >= placeholderTexts.length
      ) {
        return; // Exit if placeholderTexts is undefined or currentIndex is out of bounds
      }

      const currentText = placeholderTexts[currentIndex.current];

      if (isTyping.current) {
        if (charIndex.current < currentText.length) {
          // Typing phase
          setPlaceholderText(
            `search for '${currentText.substring(0, charIndex.current + 1)}'`
          );
          charIndex.current++;
          requestAnimationFrame(() => setTimeout(typeEffect, typingSpeed));
        } else {
          // Hold the completed text for 2 seconds
          setTimeout(() => {
            isTyping.current = false; // Switch to erasing phase
            typeEffect();
          }, holdTime);
        }
      } else {
        // Erasing phase
        if (charIndex.current > 0) {
          setPlaceholderText(
            `search for '${currentText.substring(0, charIndex.current - 1)}'`
          );
          charIndex.current--;
          requestAnimationFrame(() => setTimeout(typeEffect, erasingSpeed));
        } else {
          // Prepare for the next placeholder text
          isTyping.current = true;
          currentIndex.current =
            (currentIndex.current + 1) % placeholderTexts.length;
          charIndex.current = 0; // Reset charIndex for the new text
          typeEffect(); // Start typing the next text immediately
        }
      }
    };

    typeEffect(); // Call the typing effect to initiate the animation
  }, [placeholderTexts]);

  return (
    <>
      <div className="frontpage">
        <div className="home-slider">
          <div className="mobile-taglin dnon " style={{ height: '0' }}></div>

          {/*onClick={openLocPopup}  <SearchLocation isOpen={isPopupLocOpen} onClose={closeLocPopup} /> */}
          <div className="displnon">
            <Link to="/searchpage">
              <div className="search-container">
                <input
                  type="text"
                  placeholder={placeholderText}
                  className="search-input"
                />
                <button className="search-button">
                  <i className="fas fa-search"></i>
                </button>
              </div>
            </Link>
            {/* onClick={openPopup} <SearchPage isOpen={isPopupOpen} onClose={closePopup} /> */}
          </div>

          <div className="home-slid">
            <Bannerimage />
          </div>

          <div className="mobile-bnr">
            <Mobilebanner />
          </div>
        </div>

        <div className="card-container">
          <h3>
            {' '}
            <span>Shop by</span> category
          </h3>
          <div class="grid-container martop">
            {categories !== null ? (
              <>
                {categories?.map((item, index) => (
                  <div class="grid-item" key={index}>
                    <div
                      class="Service-boxx"
                      onClick={() => {
                        handleServiceClick(item);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div className="box-img">
                        <img
                          src={item?.image}
                          alt={`${item?.name} services at home`}
                        />
                      </div>
                      <p>{item?.name}</p>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>

        <div className="selling">
          <CardSlider />
        </div>
        <div>
          <AppDownload />
        </div>
        {/* <div className='testmonial'>
            <h2>Testimonials</h2>
            <div className='testmonial-card-wrap'>
              <Testimonial reviews={review} /></div>

          </div> */}

        <div className="why-choose">
          <h2>
            Why choose <span>Salon Ease ?</span>{' '}
          </h2>
          <div className="choose-img2">
            <div className="choose1">
              <div className="choose-img">
                <img src={Choose2} alt="" />
              </div>
              <h4>Trained, Verified Beauticians</h4>
            </div>
            <div className="choose1">
              <div className="choose-img">
                <img src={Choose3} alt="" />
              </div>
              <h4>Schedule Services Easily & always on time</h4>
            </div>
            <div className="choose1">
              <div className="choose-img">
                <img src={Choose6} alt="" />
              </div>
              <h4>Safe and Hygenic</h4>
            </div>
          </div>
          <div className="choose-img2">
            <div className="choose1">
              <div className="choose-img">
                <img src={Choose5} alt="" />
              </div>
              <h4>Best Brands in 1-Time Use Packs</h4>
            </div>
            <div className="choose1">
              <div className="choose-img">
                <img src={Choose1} alt="" />
              </div>
              <h4>Mess Free Service</h4>
            </div>
            <div className="choose1">
              <div className="choose-img">
                <img src={Choose4} alt="" />
              </div>
              <h4>Best Home Salon Services</h4>
            </div>
          </div>
        </div>

        <div className="Brand-sec">
          <h2>
            <span>Brands we use </span> in one time pack
          </h2>
          <div className="brand-box">
            <div className="choose-img1">
              <div className="choose1">
                <div className="brand1">
                  <img src={Brand4} alt="O3+ professional" />
                </div>
              </div>
              <div className="choose1">
                <div className="brand2">
                  <img src={Brand2} alt="Loreal professional" />
                </div>
              </div>
              <div className="choose1">
                <div className="brand3">
                  <img src={Brand1} alt="Rica made in Itly" />
                </div>
              </div>
            </div>
            <div className="choose-img1" style={{ marginTop: '59px' }}>
              <div className="choose1">
                <div className="brand4">
                  <img src={Brand3} alt="VLCC" />
                </div>
              </div>
              <div className="choose1">
                <div className="brand5">
                  <img src={Brand6} alt="Mindtree USA" />
                </div>
              </div>
              <div className="choose1">
                <div className="brand6">
                  <img src={Brand5} alt="Lotus herbals" />
                </div>
              </div>
            </div>
          </div>

          <div className="question">
            <NewQuestion />
          </div>
        </div>
      </div>
      {/* test code */}
      <div>
        <Citiesweserve />
      </div>
      <div className="beauty-services">
        <SeoContent />
      </div>

      <div>
        <WhatsAppButton />
      </div>
      <div>
        <MobileFooter />
      </div>
      {/* test code */}
    </>
  );
}
