import React, { useState, useEffect } from 'react';
import House from '../../assets/images/house.png';
import Pen from '../../assets/images/pen.png';
import './cartpayment.css';
import clock from '../../assets/images/clock.png';
import qr_code from '../../assets/images/qr-code.png';
import Rupee from '../../assets/images/indian-rupee.png';
import Payicon from '../../assets/images/payicon.png';
import Payicon1 from '../../assets/images/payicon2.png';
import Payicon2 from '../../assets/images/payicon3.png';
import { Await, Link, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';
import BeatLoader from 'react-spinners/BeatLoader';
import { toast } from 'react-toastify';
import { serverAPI } from '../..';
import { useUser } from '../../context/UserContext';
import { useDispatch, useSelector } from 'react-redux';
import {
  setOrderRazorpayDitail,
  setOrderUserId,
  setOrderPayment,
  setAppointmentStatus,
} from '../../utils/orderSlice';
import { selectTotalCost, selectTotalTime } from '../../utils/cartSlice';
function getCurrentDate() {
  const options = { day: 'numeric', month: 'short' };
  const currentDate = new Date().toLocaleDateString(undefined, options);
  return currentDate;
}
function formatDate(dateString, timeString) {
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(dateString + ' ' + timeString);

  return date.toLocaleString('en-US', options);
}
const Auth_key = process.env.REACT_APP_WHATSAPP_APP_KEY_AUTH;
export default function CartPayment() {
  const { userData } = useUser();
  const currentDate = getCurrentDate();
  const location = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalCost = useSelector(selectTotalCost);
  const totalTime = useSelector(selectTotalTime);
  const addressData = useSelector(state => state.order);
  const formattedDateTime = formatDate(
    addressData.slot.date,
    addressData.slot.time
  );
  const payments = addressData.paymentDetails;

  const userOrder = addressData.address;
  const calculateTotalInPaise = parseFloat(
    addressData.paymentDetails.payableAmount
  ).toFixed(2);
  const [orderData, setOrderData] = useState([addressData]);

  const searchParams = new URLSearchParams(decodeURIComponent(location.search));
  // Access the current pathname
  const currentPath = location.pathname;
  const transactionId = searchParams.get('transactionId');
  const statusC = searchParams.get('statusC');

  const [paymentMethod, setPaymentMethod] = useState(null);

  const [islodings, setIsLodings] = useState(true);
  const handlePaymentMethodChange = method => {
    setPaymentMethod(method);
    dispatch(setOrderPayment({ paymentMethod: method }));
    dispatch(setOrderUserId({ userId: userData._id }));
  };

  const status = {
    failure: 'rzp_test_LyQl8cyV8Y1ACw',
    success: 'MEUwA5dXwpQMcLggdReCQr7O',
  };

  useEffect(() => {
    setTimeout(() => {
      if (statusC && transactionId) {
        onlineTosty();
      }
    }, 2000);
  }, []);

  const onlineTosty = async () => {
    if (statusC === status.success) {
      onlineCheckoutHandel();
    } else if (statusC === status.failure) {
      const resultss = Swal.fire({
        title: 'Payment Failed',
        text: `There was an error placing your order!`,
        icon: 'error',
      });
      if (resultss.isConfirmed) {
        navigate('/payment');
      } else {
        navigate('/payment');
      }
    }
  };

  useEffect(() => {
    const myData = localStorage.getItem('address');
    const myDatas = localStorage.getItem('slot');
    const myDatass = localStorage.getItem('user');
    const myDatasss = localStorage.getItem('paymentDetails');

    if (!myData || !myDatas || !myDatass || !myDatasss) {
      navigate('/');
      localStorage.removeItem('userId');
      localStorage.removeItem('user');
      localStorage.removeItem('paymentMethod');
      localStorage.removeItem('slot');
      localStorage.removeItem('address');
      localStorage.removeItem('paymentDetails');
      localStorage.removeItem('subNo');
      localStorage.removeItem('subService');
    }
  }, []);

  const cartPaymentWhatup = async value => {
    const data = await axios.get(`${serverAPI}/comm/${value?.data?.data?._id}`);
    // await axios.post(
    //   `https://api.authkey.io/request?authkey=${Auth_key}&mobile=8603662315&country_code=91&wid=2704&button_param_value=${
    //     value?.data?.data?._id
    //   }&1=Admin&2=${value?.data?.data?.address?.name}&3=${
    //     value?.data?.data?.user?.phone
    //   }&4=${value?.data?.data?.user?.alternateNumer}&5=${
    //     value?.data?.data?.orderID
    //   }&6=${value?.data?.data?.cart?.map(e => e.service_name)}&7=${
    //     value?.data?.data?.slot?.date
    //   }&8=${value?.data?.data?.slot?.time}&9=${
    //     value?.data?.data?.address?.address
    //   },${value?.data?.data?.address?.city},${
    //     value?.data?.data?.address?.houseFlatNumber
    //   },${value?.data?.data?.address?.landmark},${
    //     value?.data?.data?.address?.states
    //   }&10=${Math.ceil(value?.data?.data?.paymentDetails?.payableAmount)} -${
    //     value?.data?.data?.paid === false ? 'Due' : 'Paid'
    //   }`
    // );
  };

  const checkoutHandler = async event => {
    event.preventDefault(); // Prevent the default behavior of form submission or link click

    setIsLodings(false);
    if (paymentMethod === 'cash-payment') {
      const appointmentData = {
        appointmentStatus: [
          {
            status: 'Order placed',
            description: `Order Placed on ${currentDate}`,
          },
          {
            status: 'Order Processing',
            description: 'We are processing your order',
          },
          {
            status: 'Service Scheduled',
            description: `Scheduled for ${formattedDateTime}`,
          },
        ],
      };
      dispatch(setAppointmentStatus(appointmentData));

      const confirmation = await Swal.fire({
        title: 'Confirmation',
        text: `Are you sure about to place the appointmentt for ${formattedDateTime}?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#38AE36',
        cancelButtonColor: '#981313',
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'No, cancel',
      });

      if (confirmation.isConfirmed) {
        try {
          localStorage.removeItem('wallet');
          const response = await axios.post(`${serverAPI}/book-appointment`, {
            data: addressData,
            appointmentStatus: [
              {
                status: 'Order placed',
                description: `Order Placed on ${currentDate}`,
              },
              {
                status: 'Order Processing',
                description: 'We are processing your order',
              },
              {
                status: 'Service Scheduled',
                description: `Scheduled for ${formattedDateTime}`,
              },
            ],
          });

          if (response.status === 200) {
            setIsLodings(true);
            showSuccessPopup();
            cartPaymentWhatup(response);
          }
        } catch (error) {
          showFailurePopup();
        }
      } else {
        setIsLodings(true); // Set loading state to true if the user cancels the action
      }

      localStorage.removeItem('wallet');
    } else if (paymentMethod === 'online-payment') {
      const myDatass = JSON.parse(localStorage.getItem('user'));
      const data = {
        name: myDatass?.name,
        amount: Math.round(payments?.payableAmount),
        number: myDatass?.phone,
        MUID: 'SEID' + Date.now(),
        transactionId: 'SETID' + Date.now(),
        nowurl: currentPath,
      };

      const confirmation = await Swal.fire({
        title: 'Confirmation',
        text: `Are you sure about to place the appointmentttt for ${formattedDateTime}?`,
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#38AE36',
        cancelButtonColor: '#981313',
        confirmButtonText: 'Yes, proceed',
        cancelButtonText: 'No, cancel',
      });

      if (confirmation.isConfirmed) {
        axios
          .post(`${serverAPI}/online/paymentNew`, { ...data })
          .then(res => {
            localStorage.removeItem('wallet');
            const redirectUrl =
              res.data.data.instrumentResponse.redirectInfo.url;
            dispatch(setOrderRazorpayDitail({ razorpayDitail: res.data.data }));
            setTimeout(() => {
              // Create a hidden anchor element
              var a = document.createElement('a');
              a.href = redirectUrl;
              a.target = '_blank';

              // Append the anchor element to the document and simulate a click
              document.body.appendChild(a);
              a.click();

              // Remove the anchor element from the document
              document.body.removeChild(a);
              setIsLodings(true);
            }, 1500);
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        setIsLodings(true); // Set loading state to true if the user cancels the action
      }
    } else {
      toast.error('Please select the payment method!', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      setIsLodings(true);
    }
  };

  const onlineCheckoutHandel = async () => {
    setIsLodings(false);

    try {
      const response = await axios.post(`${serverAPI}/book-appointment`, {
        data: addressData,
        appointmentStatus: [
          {
            status: 'Order placed',
            description: `Order Placed on ${currentDate}`,
          },
          {
            status: 'Order Processing',
            description: 'We are processing your order',
          },
          {
            status: 'Service Scheduled',
            description: `Scheduled for ${formattedDateTime}`,
          },
        ],
      });

      if (response.status === 200) {
        setIsLodings(true);
        showSuccessPopup();
      }
    } catch (error) {
      const resultss = Swal.fire({
        title: 'Payment Failed',
        text: `There was an error placing your order!`,
        icon: 'error',
      });
      if (resultss.isConfirmed) {
        navigate('/payment');
      } else {
        navigate('/payment');
      }
      setIsLodings(true);
    }
  };

  const showSuccessPopup = async () => {
    const results = await Swal.fire({
      title: 'Appointment booked successfully!',
      text: `Your Appointment Scheduled for ${formattedDateTime} is booked successfully`,
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#981313',
      confirmButtonText: 'Go to booking',
    });
    if (results.isConfirmed) {
      [
        'userId',
        'user',
        'paymentMethod',
        'slot',
        'address',
        'cart',
        'paymentDetails',
        'razorpayDitail',
        'appointmentStatus',
        'subService',
        'subNo',
      ].forEach(item => localStorage.removeItem(item));
      navigate('/currentbooking');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      [
        'userId',
        'user',
        'paymentMethod',
        'slot',
        'address',
        'cart',
        'paymentDetails',
        'razorpayDitail',
        'appointmentStatus',
        'subService',
        'subNo',
      ].forEach(item => localStorage.removeItem(item));
      navigate('/currentbooking');
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  };

  const showFailurePopup = async () => {
    const resultss = await Swal.fire({
      title: 'Something went wrong',
      text: `There was an error placing your order!`,
      icon: 'error',
    });
    if (resultss.isConfirmed) {
      navigate('/');
    }
  };
  useEffect(() => {
    const handleBackButton = () => {
      console.log('Back button pressed'); // Check if this is logged
      localStorage.removeItem('wallet');
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);
  return (
    <>
      <div className="payment-parent">
        <div class="container">
          <div class="Progress-bar">
            <div class="progress-step progress-active">1</div>
            <div className="progress-line progress-line_active"></div>
            <div class="progress-step progress-active">2</div>
            <div className="progress-line progress-line_active"></div>
            <div class="progress-step progress-active">3</div>
          </div>
        </div>

        <div className="container ">
          <div className="pay-wrap">
            <div className="head-wrap">
              <div className="pay-head">
                <div>
                  <img
                    src={House}
                    width="18px"
                    height="18px"
                    style={{ marginRight: '10px', marginTop: '-5px' }}
                    alt=""
                  />
                </div>
                <div>
                  <p>
                    {' '}
                    {addressData.address.address}{' '}
                    {addressData.address.alternateNumber}
                  </p>
                </div>
                <div className="editicon">
                  <Link to="/address">
                    <img src={Pen} width="16px" height="16px" alt="" />
                  </Link>
                </div>
              </div>

              <div className="pay-head2">
                <h3>
                  {' '}
                  <img
                    src={clock}
                    width="17px"
                    height="17px"
                    style={{ marginRight: '5px', fontWeight: '700' }}
                    alt=""
                  />{' '}
                  {formattedDateTime}
                </h3>
                <div className="editicon">
                  <Link to="/timeslot">
                    <img src={Pen} width="16px" height="16px" alt="" />
                  </Link>
                </div>
              </div>
            </div>

            <div className="pay-head3">
              <h3>Payment</h3>
            </div>

            <div className="pay-divider">
              <div className="pay-method">
                <div className="cancel">
                  <h3>Cancellation policy </h3>
                  <p>
                    Cancellations is free if made more than 3 hours before
                    service or in case a professional hasn't been assigned yet.
                    Otherwise, a cancellation fee <strong>₹200</strong> will be
                    applied.
                  </p>
                </div>

                <div className="pay-online">
                  <h4>Pay in Online</h4>
                  <div
                    className="online-box"
                    onClick={() => handlePaymentMethodChange('online-payment')}
                  >
                    <label className="rounded-checkbox">
                      <img src={qr_code} height="18px" width="18px" alt="" />
                      <p>Pay now online</p>
                      <input
                        type="checkbox"
                        checked={paymentMethod === 'online-payment'}
                        readOnly
                      />
                      <div class="checkmark"></div>
                    </label>
                  </div>
                </div>

                <div className="pay-cash">
                  <h4>Pay in Cash/Online</h4>
                  <div
                    className="cash-box"
                    onClick={() => handlePaymentMethodChange('cash-payment')}
                  >
                    <label class="rounded-checkbox">
                      <img src={Rupee} height="18px" width="18px" alt="" />
                      <p>Pay after service Cash / Online</p>
                      <input
                        type="checkbox"
                        checked={paymentMethod === 'cash-payment'}
                        readOnly
                      />
                      <div class="checkmark"></div>
                    </label>
                  </div>
                </div>
                {/* <div className='pay-text'>
                                        <h3> We accept all type of payment</h3>
                                        <div className='pay-img'>
                                            <img src={Payicon} />
                                        </div>
                                    </div> */}
                <div className="pay-text1">
                  <h3> We accept all type of payment</h3>
                  <div className="pay-img1">
                    <img src={Payicon1} />
                    <img src={Payicon2} />
                  </div>
                </div>
              </div>

              <div className="pay-bill">
                <div className="total-pay">
                  <h5>Payment summary</h5>
                  <table className="pay">
                    <tr>
                      <td>Item Total</td>
                      <th>₹ {parseFloat(payments.itemTotal).toFixed(2)}</th>
                    </tr>
                    <tr>
                      <td>Discount</td>
                      <th> - ₹ {parseFloat(payments.discount).toFixed(2)}</th>
                    </tr>

                    {payments.couponDiscount ? (
                      <tr>
                        <td>Coupon Discount</td>
                        <th className="danger">
                          - ₹ {parseFloat(payments.couponDiscount).toFixed(2)}
                        </th>
                      </tr>
                    ) : null}
                    {userData?.Wallet >= 100 ? (
                      <tr>
                        <td>Wallet</td>
                        <th className="danger">
                          -{' '}
                          {localStorage.getItem('wallet') === 'true'
                            ? '₹ 100.00'
                            : '₹ 0.00'}
                        </th>
                      </tr>
                    ) : null}
                  </table>

                  <div className="total">
                    <table>
                      <tr>
                        <td>Payable amount</td>
                        <th className="success">
                          ₹ {parseFloat(payments.payableAmount).toFixed(2)}
                        </th>
                      </tr>

                      {/* <tr>
                                                <td>Payable amount</td>
                                                <th className='success'>₹ {parseFloat(payments.payableAmount).toFixed(2)}</th>
                                            </tr> */}
                    </table>
                  </div>
                </div>

                <div className="payment-text" style={{ paddingTop: '50px' }}>
                  <h2>Happy Pampering :)</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cart_cost">
          <div class="cart_left">
            {userData?.Wallet >= 100 ? (
              <>
                {payments.couponDiscount ? (
                  <span className="total_Cost">
                    Total Cost - ₹
                    {Math.round(parseFloat(payments.payableAmount))}/-
                  </span>
                ) : (
                  <span className="total_Cost">
                    Total Cost - ₹
                    {Math.round(parseFloat(payments.payableAmount))}/-
                  </span>
                )}
              </>
            ) : (
              <>
                {payments.couponDiscount ? (
                  <span className="total_Cost">
                    Total Cost - ₹
                    {Math.round(parseFloat(payments.payableAmount))}/-
                  </span>
                ) : (
                  <span className="total_Cost">
                    Total Cost - ₹{parseFloat(payments.payableAmount)}/-
                  </span>
                )}
              </>
            )}
            {/* <span className="total_Cost">Total Cost - ₹{parseFloat(totalCost).toFixed(2)}/-</span> */}
            <span className="total_time">Total Time - {totalTime}</span>
          </div>
          <div class="cart_right" onClick={checkoutHandler}>
            <span class="service_cartbtn" style={{ width: '200px' }}>
              {islodings ? (
                <>CHECKOUT</>
              ) : (
                <BeatLoader color="#ffffff" size={10} />
              )}
            </span>
          </div>

          <div class="cart_addressbtn" onClick={checkoutHandler}>
            <span class="service_cartbtn">
              {islodings ? (
                <>CHECKOUT</>
              ) : (
                <BeatLoader color="#ffffff" size={10} />
              )}
            </span>
          </div>
        </div>
      </div>

      {/* <div className='summaryFooter fixed-bottom'>
                <div className='footer-wrap'>
                    <div className='facial' ><p >Total :</p></div>
                    <div className='facialprize'> <p>₹ 2200/-</p></div>

                    <div className='addbtn'>
                        <Link to='/cartTimeslot'></Link>
                        <button>Checkout</button>
                    </div>
                </div>

            </div> */}
    </>
  );
}
