import React, { useEffect, useState } from 'react';
import cancel from '../../assets/images/cancel.png';
import { Link, useNavigate } from 'react-router-dom';
import house from '../../assets/images/house.png';
import './applycoupon.css';
import { useUser } from '../../context/UserContext';
import {
  setOrderAddress,
  setOrderUser,
  setOrderSlot,
} from '../../utils/orderSlice';
import { useDispatch } from 'react-redux';
import BeatLoader from 'react-spinners/BeatLoader';
import { serverAPI } from '../..';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Chechoutmodal({ isOpen, onClose }) {
  const popupClass = isOpen ? 'popup open' : 'popup';
  // const { userData } = useUser();
  const dispatch = useDispatch();
  const [formadd, setFromAdd] = useState();
  const [userData, setUserData] = useState([]);
  const [address, setAddress] = useState([]);
  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        setUserData(res.data.user);
        setAddress(res.data.user.address);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getRootUser();
  }, []);

  const navigate = useNavigate();
  const [islodings, setIsLodings] = useState(true);
  const clicktonext = () => {
    setIsLodings(false);
    dispatch(setOrderUser({ user: userData }));
    if (formadd) {
      dispatch(setOrderAddress({ address: formadd }));
    }

    setTimeout(() => {
      if (formadd) {
        navigate('/timeslot');
        setIsLodings(true);
      } else {
        toast.warn('Please add or choose address to continue!', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: 'light',
        });
        setIsLodings(true);
      }
    }, 1500);
  };

  const handleCheckBoxChange = (event, address) => {
    const { checked } = event.target;
    if (checked) {
      setFromAdd(address);
      dispatch(setOrderAddress({ address: address }));
      dispatch(setOrderUser({ user: userData }));
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Disable scrolling on the body element when the pop-up is open
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling when the pop-up is closed
      document.body.style.overflow = 'auto';
    }
  }, [isOpen, userData]);
  return (
    <>
      <div className={popupClass}>
        <div className="popup-content">
          <div className="cancel-btn" style={{ display: 'block' }}>
            <img src={cancel} onClick={onClose} alt="" />
          </div>

          <div className="checkou-body">
            <div className="address-heading">
              <h3>
                {' '}
                <img
                  src={house}
                  width="18px"
                  height="20px"
                  style={{ marginRight: '10px', marginBottom: '4px' }}
                  alt=""
                />{' '}
                Choose Address
              </h3>
            </div>
            <div className="content-body">
              <div className="address_link">
                <Link to="/address" className="linkaddress">
                  <span style={{ fontSize: '17px' }}>+</span> Add New
                </Link>
              </div>

              {address?.home?.map((homeAddress, index) => (
                <div className="address1" key={index}>
                  <label className="d-flex" style={{ width: '100%' }}>
                    <input
                      style={{ marginTop: '11px' }}
                      type="radio"
                      name="radio"
                      className="Radiobtn"
                      onChange={e => {
                        handleCheckBoxChange(e, homeAddress);
                      }}
                    />
                    <div style={{ paddingLeft: '20px', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px',
                        }}
                      >
                        <h6 style={{ margin: '0px', padding: '0px' }}>
                          {homeAddress.name}
                        </h6>
                        <p className="address-type">Home</p>
                      </div>
                      <p style={{ paddingLeft: '0px' }}>
                        {homeAddress.houseFlatNumber +
                          ', ' +
                          homeAddress.address +
                          ', ' +
                          homeAddress.city +
                          ', ' +
                          homeAddress.states}
                        , {homeAddress.alternateNumber}
                      </p>
                    </div>
                  </label>
                </div>
              ))}

              {address?.other?.map((otherAddress, index) => (
                <div className="address2" key={index}>
                  <label className="d-flex" style={{ width: '100%' }}>
                    <input
                      style={{ marginTop: '11px' }}
                      type="radio"
                      name="radio"
                      value="option1"
                      className="Radiobtn"
                      onChange={e => {
                        handleCheckBoxChange(e, otherAddress);
                      }}
                    />
                    <div style={{ paddingLeft: '20px', width: '100%' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '5px',
                        }}
                      >
                        <h6 style={{ margin: '0px', padding: '0px' }}>
                          {otherAddress.name}
                        </h6>
                        <p className="address-type">Other</p>
                      </div>
                      <p style={{ paddingLeft: '0px' }}>
                        {otherAddress.houseFlatNumber +
                          ', ' +
                          otherAddress.address +
                          ', ' +
                          otherAddress.city +
                          ', ' +
                          otherAddress.states}
                        , {otherAddress.alternateNumber}
                      </p>
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <div
              className="ftrbtn"
              style={{ cursor: 'pointer' }}
              onClick={clicktonext}
            >
              <div className="btnbox ">
                <button>
                  {islodings ? (
                    <>ADD TIME SLOT</>
                  ) : (
                    <BeatLoader color="#ffffff" size={10} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
