import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './manageaddress.css';

import LoginModal from '../login/loginModal';
import Precision from '../../assets/images/gps.svg';
import '../modal/mapModal.css';
import axios from 'axios';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import HashLoader from 'react-spinners/HashLoader';
import { serverAPI } from '../..';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from 'react-spinners/BeatLoader';
import ProfileHeder from './profileHeder';
import ProfileMenu from './ProfileMenu';
import loctn from '../../assets/images/pin.png';

export default function Newaddress() {
  const navigate = useNavigate(); // Initialize the navigate function
  const [userData, setUserData] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(true);
  const [isPopupOpens, setIsPopupOpens] = useState(false);
  const closePopups = () => {
    navigate('/');
    setIsPopupOpens(false);
  };
  const [formData, setFormData] = useState({
    name: '',
    alternateNumber: '',
    houseFlatNumber: '',
    landmark: '',
    address: '',
    states: '',
    city: '',
  });

  const getRootUser = async () => {
    try {
      const res = await axios.get(`${serverAPI}/auth/is-login`, {
        withCredentials: true,
      });
      if (res.status === 200) {
        const userData = res.data.user;
        setUserData(userData);
        setPageLoaded(false);
      }
    } catch (err) {
      setIsPopupOpens(true);
    }
  };
  useEffect(() => {
    getRootUser();
  }, []);
  const getFormattedAddress = () => {
    if (userData?.address.home && userData?.address.home.length > 0) {
      return userData?.address.home[0].address;
    } else if (userData?.address.other && userData?.address.other.length > 0) {
      return userData?.address.other[0].address;
    } else {
      return 'No address available';
    }
  };

  const [currentAddress, setCurrentAddress] = useState('');
  const [areaName, setAreaName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [islodings, setIsLodings] = useState(true);
  const [newAdd, setNewAdd] = useState(false);
  const [isloding, setIsLoding] = useState(true);

  // const handelCurrentLocation = () => {

  //     setShowModal(true);
  //     if (navigator.geolocation) {
  //         navigator.geolocation.watchPosition(
  //             (position) => {
  //                 const { latitude, longitude } = position.coords;

  //                 // Fetch address using Geocoding API
  //                 axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
  //                     params: {
  //                         latlng: `${latitude},${longitude}`,
  //                         key: `${process.env.REACT_APP_API_KEY}`, // Replace with your API key
  //                     }
  //                 })
  //                     .then(response => {

  //                         // Extract the formatted address from the response
  //                         const formattedAddress = response.data.results[0].formatted_address;

  //                         // Split the formatted address by commas
  //                         const addressParts = formattedAddress.split(', ');

  //                         // Now, addressParts is an array containing individual parts of the address
  //                         // For example, addressParts[0] contains "Fjx4+Rc"
  //                         // and addressParts[1] contains "Kalikapur"
  //                         // You can access and use these parts as needed

  //                         const addresslist = []
  //                         for (let i = 1; i < addressParts.length; i++) {
  //                             addresslist.push(addressParts[i])
  //                         }
  //                         setAreaName(addresslist[0])
  //                         // If you want to join the parts back into a string with commas
  //                         const joinedAddress = addresslist.join(', ');
  //                         setCurrentAddress(joinedAddress);
  //                         setFormData({
  //                             ...formData,
  //                             mise: response.data.results[0],
  //                             address: formattedAddress,
  //                         });

  //                         setTimeout(() => {
  //                             setNewAdd(true);
  //                             setShowModal(false);
  //                         }, 1500);

  //                     })
  //                     .catch(error => {
  //                         setShowModal(false);

  //                     });
  //             },
  //             (error) => {
  //                 setShowModal(false);

  //             }
  //         );
  //     } else {
  //         setShowModal(false);
  //     }
  // }

  useEffect(() => {
    setTimeout(() => {
      setIsLoding(false);
    }, 1500);
  }, []);

  // const handleAddressChange = (address) => {
  //     setFormData({
  //         ...formData,
  //         mise: address,
  //         address: address.label,
  //     });
  //     const addressParts = address.label.split(', ');

  //     const addresslist = []
  //     for (let i = 0; i < addressParts.length; i++) {
  //         addresslist.push(addressParts[i])
  //     }
  //     setAreaName(addresslist[0])
  //     // If you want to join the parts back into a string with commas
  //     const joinedAddress = addresslist.join(', ');
  //     setCurrentAddress(joinedAddress);
  //     setNewAdd(true);
  // };

  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState('');
  const [results, setResults] = useState([]);

  const fetchGooglePlaces = async input => {
    try {
      //   const response = await axios.post(`${serverAPI}/getPlaces`, {
      //     input: input,
      //   });
      if (input !== '') {
        const response = await axios.get(
          `${serverAPI}/coupons/search/address/${input}`
        );
        console.log(response.data.predictions, 'hello brother');
        setResults(response.data.predictions);
      } else {
        setResults([]);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  // Update `debouncedValue` after 1.5 seconds of no typing
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 1100);

    // Cleanup timer if inputValue changes
    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  // Trigger API call when `debouncedValue` updates
  useEffect(() => {
    fetchGooglePlaces(debouncedValue);
  }, [debouncedValue]);

  const handleInputChanges = e => {
    setInputValue(e.target.value);
  };

  const extractAddressDetails = address => {
    // Define regex pattern to capture city and state from the address
    // const regex = /^(.*?),\s*(.*?),\s*(.*?),\s*(.*?),\s*(\d{6}),\s*(.*)$/;
    // const match = address.match(regex);

    const parts = address.split(',').map(part => part.trim());
    setFormData({
      ...formData,
      address: `${parts[0]}, ${parts[1]}, ${parts[2]}`,
      city: `${parts[parts.length - 4]}, ${parts[parts.length - 2]}`, // e.g., "Bengaluru"
      states: parts[parts.length - 3], // e.g., "Karnataka"
    });

    // if (match) {
    //   const [, fullAddress, marketArea, gate, city, pincode, country] = match;
    //   console.log(match, 'hellllllllllllllllllllllll');

    //   // Set only the city and state
    // } else {
    //   console.log('Invalid address format');
    // }
  };
  const handleAddressChange = address => {
    if (currentAddress) {
      setCurrentAddress('');
      setNewAdd(false);
    }

    // Extract city and state from the address
    extractAddressDetails(address.description);

    const addressParts = address.description.split(', ');

    const addresslist = [];
    for (let i = 0; i < addressParts.length; i++) {
      addresslist.push(addressParts[i]);
    }

    // Update areaName to the first part of the address (i.e., the market area or specific landmark)
    setAreaName(addresslist[0]);

    // Join the parts back into a single address string
    const joinedAddress = addresslist.join(', ');

    setCurrentAddress(joinedAddress);
    setNewAdd(true);
    setInputValue('');
    setResults([]);
  };

  //   const handelCurrentLocation = () => {
  //     setShowModal(true);
  //     if (navigator.geolocation) {
  //       navigator.geolocation.watchPosition(
  //         position => {
  //           const { latitude, longitude } = position.coords;

  //           axios
  //             .get(`${serverAPI}/geocode`, {
  //               params: {
  //                 lat: latitude,
  //                 lng: longitude,
  //               },
  //             })
  //             .then(response => {
  //               const formattedAddress =
  //                 response.data.results[0].formatted_address;
  //               const addressParts = formattedAddress.split(', ');

  //               const addressList = [];
  //               for (let i = 1; i < addressParts.length; i++) {
  //                 addressList.push(addressParts[i]);
  //               }

  //               // Use the addressList as needed
  //               const joinedAddress = addressList.join(', ');
  //               setAreaName(addressList[0]); // Assuming addressList[0] should be set as area name
  //               setCurrentAddress(joinedAddress);
  //               setFormData({
  //                 ...formData,
  //                 mise: response.data.results[0],
  //                 address: joinedAddress,
  //               });
  //               setTimeout(() => {
  //                 setNewAdd(true);
  //                 setShowModal(false);
  //               }, 1500);
  //             })
  //             .catch(error => {
  //               setShowModal(false);
  //             });
  //         },
  //         error => {
  //           setShowModal(false);
  //         }
  //       );
  //     } else {
  //       setShowModal(false);
  //     }
  //   };

  //   const fetchGooglePlaces = async input => {
  //     try {
  //       const response = await axios.post(`${serverAPI}/getPlaces`, {
  //         input: input,
  //       });
  //       setResults(response.data.predictions);
  //     } catch (error) {
  //       console.error('Error fetching data: ', error);
  //     }
  //   };

  //   const handleInputChanges = e => {
  //     setInputValue(e.target.value);
  //     fetchGooglePlaces(e.target.value);
  //   };

  const [addressType, setAddressType] = useState('home-address');

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleAddressTypeChange = type => {
    setAddressType(type);
  };

  const handleSave = () => {
    setIsLodings(false);
    // Alternate number validation
    const alternateNumberRegex = /^\d{10}$/;
    if (!alternateNumberRegex.test(formData.alternateNumber)) {
      toast.error('Alternate Number should be 10 digits', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      setIsLodings(true);
      return;
    }

    if (formData.name === '') {
      toast.warn('Please enter your name', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      setIsLodings(true);
    } else if (formData.alternateNumber === '') {
      toast.warn('Please enter your Alternate Number', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      setIsLodings(true);
    } else if (formData.address === '') {
      toast.warn('Please enter your address', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      setIsLodings(true);
    } else if (formData.states === '') {
      toast.warn('Please enter your state', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      setIsLodings(true);
    } else if (formData.city === '') {
      toast.warn('Please enter your city', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      setIsLodings(true);
    } else {
      // Send an API request to update the address based on 'addressType'
      axios
        .put(`${serverAPI}/auth/user/${userData._id}/${addressType}`, formData)
        .then(response => {
          if (response.status === 200) {
            toast.success('Address updated successfully', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'light',
            });
            setTimeout(() => {
              setIsLodings(true);
              navigate('/manageaddress');
              // Now navigate to the login-otp page with the formatted phone number in state
            }, 2500);
            // You may want to reset the form or perform other actions here
          } else {
            toast.error('Failed to update address', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: 'light',
            });
            setTimeout(() => {
              setIsLodings(true);
              // Now navigate to the login-otp page with the formatted phone number in state
            }, 2500);
          }
        })
        .catch(error => {
          setIsLodings(true);
          toast.error('An error occurred while updating the address');
        });
    }
  };
  // const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {pageLoaded ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '88vh',
            }}
          >
            <HashLoader color="#981313" size={50} />
          </div>
          <LoginModal isOpen={isPopupOpens} onClose={closePopups} />
        </>
      ) : (
        <div className="profile-parent">
          <div className="container">
            <div className="currentbooking-wrap">
              <ProfileHeder
                name={userData?.name}
                phone={userData?.phone}
                address={getFormattedAddress()}
              />
              <div className="Profile-divider">
                <ProfileMenu />
                <div className="current-content">
                  <div className="address-form">
                    <div className="head-address">
                      <h3>ADD NEW ADDRESS </h3>
                    </div>
                    {/* <form class="nosubmit" style={{ marginTop: '20px' }}>
                                                <GooglePlacesAutocomplete
                                                    className="nosubmit"
                                                    apiKey={process.env.REACT_APP_API_KEY}
                                                    apiOptions={{ region: 'IN' }}
                                                    selectProps={{
                                                        styles: {
                                                            input: (provided) => ({
                                                                ...provided,
                                                                color: 'black',
                                                                height: '38px',
                                                                fontSize: '14px'
                                                            }),

                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                backgroundColor: state.isFocused ? '#981313' : 'transparent', // Change background color on hover
                                                                color: state.isFocused ? 'white' : 'black'
                                                            }),
                                                            singleValue: (provided) => ({
                                                                ...provided,
                                                                color: 'black',
                                                            }),
                                                        },
                                                        currentAddress, // Set the value to the currentAddress state
                                                        onChange: handleAddressChange, // Handle changes and update state
                                                        placeholder: 'Search for your society/apartment/location',

                                                    }} />
                                            </form> */}
                    <form className="loc-input" style={{ width: '90%' }}>
                      <input
                        type="text"
                        value={inputValue}
                        onChange={handleInputChanges}
                        placeholder="Search for your society/apartment/location"
                        style={{
                          marginTop: 0,
                        }}
                      />

                      {results.length > 0 ? (
                        <div className="address-option2">
                          {results.map((result, index) => (
                            <div
                              className="option2"
                              key={index}
                              onClick={() => {
                                handleAddressChange(result);
                              }}
                            >
                              <img
                                src={loctn}
                                width="18px"
                                height="17px"
                                alt=""
                              />
                              <div className="option-detl">
                                <h3 style={{ padding: '0px' }}>
                                  {result.description.split(', ')[0]}
                                </h3>
                                <p>{result.description}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </form>
                    <div className="anchorlink">
                      {showModal ? (
                        <div
                          style={{
                            display: 'flex',
                            alignContent: 'center',
                            justifyContent: 'center',
                            padding: '5px 0px',
                          }}
                        >
                          <PropagateLoader
                            color="#981313"
                            size={10}
                            speedMultiplier={0.9}
                          />
                        </div>
                      ) : (
                        <></>
                        // <p
                        //   style={{
                        //     margin: '0px',
                        //     paddingTop: '30px',
                        //     color: '#981313',
                        //     cursor: 'pointer',
                        //   }}
                        //   onClick={handelCurrentLocation}
                        // >
                        //   <img
                        //     src={Precision}
                        //     width="16px"
                        //     style={{ marginRight: '5px' }}
                        //     alt=""
                        //   />
                        //   Use currect location
                        // </p>
                      )}
                    </div>

                    {newAdd ? (
                      <>
                        <div className="textarea">
                          <h5>{areaName}</h5>
                          <p>{currentAddress}</p>
                        </div>
                      </>
                    ) : null}

                    <form className="form2">
                      <div class="inputbox">
                        <input
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                        <span>Enter your name</span>
                      </div>
                      <div class="inputbox">
                        <input
                          type="number"
                          name="alternateNumber"
                          pattern="[0-9]{0,10}"
                          value={formData.alternateNumber}
                          onChange={handleInputChange}
                          required
                        />
                        <span>Alternate number</span>
                      </div>
                      <div class="inputbox">
                        <input
                          type="text"
                          name="address"
                          value={formData.address}
                          onChange={handleInputChange}
                          required
                        />
                        <span>Enter your Address</span>
                      </div>
                      <div class="inputbox">
                        <input
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={handleInputChange}
                          required
                        />
                        <span>Enter your City</span>
                      </div>
                      <div class="inputbox">
                        <input
                          type="text"
                          name="states"
                          value={formData.states}
                          onChange={handleInputChange}
                          required
                        />
                        <span>Enter your State</span>
                      </div>
                      <div class="inputbox">
                        <input
                          type="text"
                          name="houseFlatNumber"
                          value={formData.houseFlatNumber}
                          onChange={handleInputChange}
                          required
                        />
                        <span>House/Flat number (optional)</span>
                      </div>
                      <div class="inputbox">
                        <input
                          type="text"
                          name="landmark"
                          value={formData.landmark}
                          onChange={handleInputChange}
                          required
                        />
                        <span>Landmark (optional)</span>
                      </div>
                    </form>
                    <br />

                    <div className="addresstype">
                      <button
                        style={{ marginRight: '15px' }}
                        className={
                          addressType === 'home-address' ? 'active' : ''
                        }
                        onClick={() => handleAddressTypeChange('home-address')}
                      >
                        Home
                      </button>
                      <button
                        className={
                          addressType === 'other-address' ? 'active' : ''
                        }
                        onClick={() => handleAddressTypeChange('other-address')}
                      >
                        Other
                      </button>
                    </div>

                    <div style={{ width: '90%' }} className="newadd-btn">
                      <button
                        style={{ marginTop: '10px', cursor: 'pointer' }}
                        className="btn-1"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        CANCEL
                      </button>
                      <button
                        style={{ marginTop: '10px', cursor: 'pointer' }}
                        className="btn-2"
                        onClick={handleSave}
                      >
                        {islodings ? (
                          <>SAVE </>
                        ) : (
                          <BeatLoader color="#ffffff" size={10} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
