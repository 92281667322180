import React, { useState, useEffect } from 'react';
import cancel from '../../assets/images/cancel.png';
import './mapModal.css';
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from 'axios';
// import pins from '../../assets/images/location-pin.png'

const containerStyle = {
    width: "100%",
    height: "450px",
};

export default function MapModal({ show, onClose }) {
    const [currentLocation, setCurrentLocation] = useState(null);
    const [newCurrent, setNewCurrent] = useState(null);
    const [currentAddress, setCurrentAddress] = useState("");
    const [areaName, setAreaName] = useState('')
    const [map, setMap] = useState(null); // State to store the map object

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setCurrentLocation({ lat: latitude, lng: longitude });
                    setNewCurrent({ lat: latitude, lng: longitude });
                    // Fetch address using Geocoding API
                    axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
                        params: {
                            latlng: `${latitude},${longitude}`,
                            key: 'AIzaSyAQC_edJGacx2iCQrWroNNeVLiYFVnAgwM', // Replace with your API key
                        }
                    })
                        .then(response => {
                            // Extract the formatted address from the response
                            const formattedAddress = response.data.results[0].formatted_address;

                            // Split the formatted address by commas
                            const addressParts = formattedAddress.split(', ');

                            // Now, addressParts is an array containing individual parts of the address
                            // For example, addressParts[0] contains "Fjx4+Rc"
                            // and addressParts[1] contains "Kalikapur"
                            // You can access and use these parts as needed

                            const addresslist = []
                            for (let i = 1; i < addressParts.length; i++) {
                                addresslist.push(addressParts[i])
                            }
                            setAreaName(addresslist[0])
                            // If you want to join the parts back into a string with commas
                            const joinedAddress = addresslist.join(', ');
                            setCurrentAddress(joinedAddress);
                        })
                        .catch(error => {
                            console.error("Error getting address:", error);
                        });
                },
                (error) => {
                    console.error("Error getting user's location:", error);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);


    const handleMapLoad = (map) => {
        setMap(map); // Store the map object when it becomes available
    };

    // Modify the handleMapDrag function to update the marker's position
    const handleMapDrag = () => {
        if (map) {
            // Ensure map is available
            const newCenter = map.getCenter();
            setCurrentLocation({ lat: newCenter.lat(), lng: newCenter.lng() });
            // Update the marker's position to the new center
            setNewCurrent({ lat: newCenter.lat(), lng: newCenter.lng() });
            // Fetch address using Geocoding API
            axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
                params: {
                    latlng: `${newCenter.lat()},${newCenter.lng()}`,
                    key: 'AIzaSyAQC_edJGacx2iCQrWroNNeVLiYFVnAgwM',
                }
            })
                .then(response => {
                    // Extract the formatted address from the response
                    const formattedAddress = response.data.results[0].formatted_address;

                    // Split the formatted address by commas
                    const addressParts = formattedAddress.split(', ');

                    // Now, addressParts is an array containing individual parts of the address
                    // For example, addressParts[0] contains "Fjx4+Rc"
                    // and addressParts[1] contains "Kalikapur"
                    // You can access and use these parts as needed

                    const addresslist = []
                    for (let i = 1; i < addressParts.length; i++) {
                        addresslist.push(addressParts[i])
                    }
                    setAreaName(addresslist[0])
                    // If you want to join the parts back into a string with commas
                    const joinedAddress = addresslist.join(', ');
                    setCurrentAddress(joinedAddress);
                })
                .catch(error => {
                    console.error("Error getting address:", error);
                });
        }
    };



    if (!show) return null;

    return (
        <div className="modal">
            <div className="modal-content">
                <div className='cancel-btn'><img src={cancel} onClick={onClose} alt='' /></div>
                <div className='content'>
                    <div>
                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                {window.google && window.google.maps && (
                                    <GoogleMap
                                        mapContainerStyle={containerStyle}
                                        center={currentLocation}
                                        zoom={16}
                                        onLoad={handleMapLoad} // Call handleMapLoad when the map is loaded
                                        onDragEnd={handleMapDrag} // Call handleMapDrag when the map is dragged
                                        options={{
                                            disableDefaultUI: true, // Disable all default UI controls
                                        }}
                                    >
                                        {/* <Marker
                                            position={newCurrent}
                                            icon={{
                                                url: pins,
                                                scaledSize: new window.google.maps.Size(50, 50),
                                            }}
                                        /> */}
                                    </GoogleMap>
                                )}
                            <div style={{
                                position: 'absolute',
                                zIndex: '1', // Place it above the map
                            }}>
                                {/* <img src={pins} alt='' width={40} height={40} /> */}

                            </div>

                        </div>



                        <div className='map-details' style={{ color: 'black', backgroundColor: 'white', height: '120px', padding: '20px', marginTop: '5px' }}>
                            <h5>Current Address:</h5>
                            <p style={{ margin: "0", padding: "0" }}>{areaName}</p>
                            <p style={{ fontSize: "14px", margin: "0", padding: "0" }}>{currentAddress}</p>
                        </div>
                    </div>
                    <div className='confirm-btn'>
                        <div className='btn1'>
                            <button>Confirm Address</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}