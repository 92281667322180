import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import Header from '../../layouts/header'
// import Carousel from 'react-multi-carousel'
import './cartTimeSlot.css';
import 'react-multi-carousel/lib/styles.css';
import House from '../../assets/images/house.png';
// import Stars from '../../assets/images/stars.png'
// import imag1 from '../../assets/images/1.png'
import Pen from '../../assets/images/pen.png';
import clock from '../../assets/images/clock.png';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from '../../context/UserContext';
import axios from 'axios';
import HashLoader from 'react-spinners/HashLoader';
import PropagateLoader from 'react-spinners/PropagateLoader';
import { serverAPI } from '../..';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BeatLoader from 'react-spinners/BeatLoader';
import { setOrderSlot, setOrderUser } from '../../utils/orderSlice';
import { selectTotalCost, selectTotalTime } from '../../utils/cartSlice';
import { DateTime } from 'luxon';

export default function CartTimeslote() {
  const currentDates = new Date();
  const month = currentDates.toLocaleString('default', { month: 'long' });
  const year = currentDates.getFullYear();
  const [currentDate, setCurrentDate] = useState(DateTime.now());

  const { userData } = useUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [islodings, setIsLodings] = useState(true);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    const myData = localStorage.getItem('address');
    setTimeout(() => {
      if (!myData) {
        navigate('/');
      }
    }, 500);
  }, []);

  const [slot, setSlot] = useState();
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [rowss, setRowss] = useState(true);
  const addressData = useSelector(state => state.order);
  const totalCost = parseFloat(
    addressData?.paymentDetails?.payableAmount
  ).toFixed(2);
  const totalTime = useSelector(selectTotalTime);
  const [activeDate, setActiveDate] = useState(null);

  const handleDateClick = clickedDate => {
    setActiveDate(clickedDate.date); // Set the clicked date as active
    // Define the URL for the second GET request based on the clicked date
    setSlot('');
    setAvailableTimeSlots([]);

    const secondApiUrl = `${serverAPI}/available-time-slots/${clickedDate.date}`;

    // Use Axios to make the second GET request
    axios
      .get(secondApiUrl)
      .then(response => {
        // Handle the second GET request's successful response here
        setAvailableTimeSlots(response.data);
        setRowss(true);
        if (response.data.message) {
          setRowss(false);
        }
      })
      .catch(err => {
        // Handle errors for the second request
      });
  };

  const handleDateandTimeClick = clickedTime => {
    setSlot({
      ...slot,
      date: activeDate,
      time: clickedTime,
    });
  };

  const clicktonext = () => {
    setIsLodings(false);

    if (slot?.date || slot?.date) {
      console.log(userData, 'sssssssssssssssssssssssssssssssss');
      dispatch(setOrderUser({ user: userData }));
      dispatch(setOrderSlot({ slot: slot }));
      setTimeout(() => {
        navigate('/payment');
        setIsLodings(true);
      }, 1500);
    } else {
      toast.error('Please select date & time both!', {
        position: 'top-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      });
      setIsLodings(true);
    }
  };

  const getAvailableDatesNew = async () => {
    const response = await axios.get(`${serverAPI}/available-dates`);
    if (response.data.length > 0) {
      setAvailableDates(response.data);
      setActiveDate(response.data[0].date);
      const slottimeDAta = await axios.get(
        `${serverAPI}/available-time-slots/${response.data[0].date}`
      );
      setAvailableTimeSlots(slottimeDAta.data);
    }

    // getTime();
  };
  useEffect(() => {
    getAvailableDatesNew();
    // Define the URL for the first GET request
    // const apiUrl = `${serverAPI}/available-dates`;

    // // Use Axios to make the first GET request
    // axios
    //   .get(apiUrl)
    //   .then(response => {
    //     // Handle successful response here
    //     setAvailableDates(response.data);
    //     setActiveDate(response.data[0].date);

    //     getTime();
    //   })

    //   .catch(err => {
    //     // Handle errors for both requests
    //   });
  }, [addressData.slot]);

  useEffect(() => {
    // Update the current date and time every minute
    const interval = setInterval(() => {
      setCurrentDate(DateTime.now());
    }, 60000); // 60,000 milliseconds = 1 minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Check if the current time is within the specified frame
  const isWithinTimeFrame = currentDate.hour >= 0 && currentDate.hour < 24;

  // Calculate the next day's date
  const nextDayDate = currentDate.plus({ days: 1 });

  // Format the date for display

  const formattedDate = isWithinTimeFrame
    ? currentDate.toFormat('yyyy-MM-dd')
    : nextDayDate.toFormat('yyyy-MM-dd');

  const getTime = () => {
    if (Object.keys(addressData.slot).length > 0) {
      const data = [];
      data.push({
        date: addressData.slot.date,
        time: addressData.slot.time,
      });
      const convertedData = data.reduce((result, item, index) => {
        result[index] = item;
        return result;
      }, {});
      const finalData = convertedData[0];
      const secondApiUrl = `${serverAPI}/available-time-slots/${addressData.slot.date}`;
      // Define the URL for the second GET request based on the clicked date
      setActiveDate(formattedDate);
      // Use Axios to make the second GET request
      axios
        .get(secondApiUrl)
        .then(response => {
          // Handle the second GET request's successful response here
          setAvailableTimeSlots(response.data);
          setSlot(finalData);
          setRowss(true);
          if (response.data.message) {
            setRowss(false);
          }
        })
        .catch(err => {
          // Handle errors for the second request
        });
      setActiveDate(addressData.slot.date);
    } else {
      // Define the URL for the second GET request based on the first date  2023-09-17
      const secondApiUrl = `${serverAPI}/available-time-slots/${formattedDate}`;
      // Define the URL for the second GET request based on the clicked date
      setActiveDate(formattedDate);
      // Use Axios to make the second GET request
      axios
        .get(secondApiUrl)
        .then(response => {
          // Handle the second GET request's successful response here
          setAvailableTimeSlots(response.data);

          setRowss(true);
          if (response.data.message) {
            setRowss(false);
          }
        })
        .catch(err => {
          // Handle errors for the second request
        });
    }
  };

  return (
    <>
      <div className="timeslot-parent">
        <div class="container">
          <div class="Progress-bar">
            <div class="progress-step progress-active">1</div>
            <div className="progress-line progress-line_active"></div>
            <div class="progress-step progress-active">2</div>
            <div className="progress-line"></div>
            <div class="progress-step">3</div>
          </div>
        </div>
        <div className="container">
          <div className="time-wrap">
            <div className="timeslot ">
              <div>
                <img
                  src={House}
                  width="18px"
                  height="18px"
                  style={{ marginRight: '10px', marginTop: '-5px' }}
                  alt=""
                />
              </div>
              <div>
                <p>
                  {addressData.address.address},{' '}
                  {addressData.address.alternateNumber}
                </p>
              </div>
              <div className="editicon">
                <Link to="/address">
                  <img src={Pen} width="18px" height="18px" alt="" />
                </Link>
              </div>
            </div>
            <div className="time-heading">
              <h3>
                {' '}
                <img
                  src={clock}
                  width="26px"
                  height="26px"
                  style={{ marginRight: '5px', marginTop: '-3px' }}
                  alt=""
                />{' '}
                Choose date and time slot
              </h3>
            </div>
            <div className="date">
              <h5>Choose date</h5>
            </div>
            <div className="time-box">
              <h5>
                {month}, {year}
              </h5>
              <div className="scroll-wrap">
                <div className="my-2 time-box_scroll">
                  {availableDates?.map((date, index) => (
                    <div
                      className={`${
                        index === 0 ? ' timebox1' : 'timebox1 marginleft'
                      }  ${activeDate === date.date ? 'timebox1-active' : ''}`}
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={e => handleDateClick(date)}
                    >
                      <p>{date.dayOfWeek.slice(0, 3)}</p>
                      <h3>{date.date.slice(-2)}</h3>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="choose-time-slot">
              <p>Choose time slot</p>
              <div className="time-boxes">
                <div className="row time-row mob-row">
                  {/* {
                                        availableTimeSlots.length > 0 ?
                                            <>
                                                {availableTimeSlots?.map((slots, slotIndex) => (
                                                    <div className={`col-md-3 col-4`} style={{ display: slots.isAvailable ? "block" : "none" }} key={slotIndex}>
                                                        {slots.maxBookingsNotFull ? (
                                                            <div className={`${slotIndex === 0 ? 'time-box1' : 'time-box1'} ${slots.time === slot?.time ? 'timebox1-active' : ''}`} onClick={() => handleDateandTimeClick(slots.time)} style={{ cursor: 'pointer' }}>
                                                                {slots.time}
                                                            </div>
                                                        ) : (
                                                            <div className='slotfull' style={{ cursor: "not-allowed" }}>
                                                                <span>Slot Full</span>
                                                                <h6>{slots.time}</h6>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </>
                                            :
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "35vh" }}>
                                                <BeatLoader color="#93294B" size={10} />
                                            </div>
                                    } */}
                  {availableTimeSlots.length > 0 ? (
                    availableTimeSlots.some(slot => slot.isAvailable) ? (
                      <>
                        {availableTimeSlots?.map((slots, slotIndex) => (
                          <div
                            className={`col-md-3 col-4`}
                            style={{
                              display: slots.isAvailable ? 'block' : 'none',
                            }}
                            key={slotIndex}
                          >
                            {slots.isAvailable ? (
                              slots.maxBookingsNotFull ? (
                                <div
                                  className={`${
                                    slotIndex === 0 ? 'time-box1' : 'time-box1'
                                  } ${
                                    slots.time === slot?.time
                                      ? 'timebox1-active'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    handleDateandTimeClick(slots.time)
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  {slots.time}
                                </div>
                              ) : (
                                <div
                                  className="slotfull"
                                  style={{ cursor: 'not-allowed' }}
                                >
                                  <span>Slot Full</span>
                                  <h6>{slots.time}</h6>
                                </div>
                              )
                            ) : null}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '35vh',
                        }}
                      >
                        <h6>Sorry no slots available for today.</h6>
                      </div>
                    )
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '35vh',
                      }}
                    >
                      <BeatLoader color="#93294B" size={10} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="cart_cost">
          <div class="cart_left">
            <span className="total_Cost">
              Total Cost - ₹{parseInt(totalCost)}/-
            </span>
            <span className="total_time">Total Time - {totalTime}</span>
          </div>
          <div
            class="cart_right"
            style={{ cursor: 'pointer' }}
            onClick={clicktonext}
          >
            <span class="service_cartbtn">
              {' '}
              {islodings ? (
                <>CHECKOUT</>
              ) : (
                <BeatLoader color="#ffffff" size={10} />
              )}
            </span>
          </div>

          <div
            class="cart_addressbtn"
            style={{ cursor: 'pointer' }}
            onClick={clicktonext}
          >
            <span class="service_cartbtn">
              {islodings ? (
                <>CHECKOUT</>
              ) : (
                <BeatLoader color="#ffffff" size={10} />
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
